// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Roboto+Mono:wght@100;200;300&display=swap');

// Define @font-face for Neue Machina Ultrabold
@font-face {
  font-family: 'Neue Machina Ultrabold';
  src: url('../assets/fonts/NeueMachina-Ultrabold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

// Define @font-face for Neue Machina Regular
@font-face {
  font-family: 'Neue Machina Regular';
  src: url('../assets/fonts/NeueMachina-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

// Define @font-face for Helvetica Neue Medium Extended
@font-face {
  font-family: 'Helvetica Neue Medium Extended';
  src: url('../assets/fonts/Helvetica Neue Medium Extended.ttf')
    format('truetype');
  font-weight: medium;
  font-style: normal;
}

// Define @font-face for Helvetica Neue Light
@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('../assets/fonts/HelveticaNeueLt.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

$fontHeader: 'Neue Machina regular', sans-serif;
$fontHeaderBold: 'Neue Machina Ultrabold', sans-serif;
// $fontText: 'Helvetica Neue Light', 'Arial', sans-serif;
$fontText: 'Roboto Mono', monospace;
$fontMono: 'Roboto Mono', monospace;

// Colors
$colorBlack: #111;
$colorWhite: #fff;
$colorSpecial: #41e0d0;
