@import './scss/variables';
@import './scss/mixins';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html::-webkit-scrollbar {
  display: none;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1400px;
  margin: 0 auto;
  font-family: $fontText;
  color: $colorWhite;
  cursor: crosshair;
}

a {
  cursor: crosshair;
  text-decoration: none;
  color: $colorWhite;
}

ul {
  list-style-type: none;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: -1;
  }
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -2;
  }
}

.page {
  /* this two goes together */
  height: calc(100vh - 3rem);
  margin: 1.5rem;
  /* this two goes together */

  border: 1px solid $colorWhite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
}

.header {
  color: $colorWhite;
  height: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    display: flex;
    align-items: center;
    font-family: $fontHeaderBold;
    font-size: 5rem;

    small {
      font-size: 2rem;
    }
  }

  a {
    font-family: $fontMono;

    &:hover {
      color: $colorSpecial;
    }
  }

  .header-links {
    margin: 0.7rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .internal-links {
      width: auto;

      a {
        font-family: $fontHeader;
        line-height: 1.2;
      }
    }

    .external-links {
      width: auto;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.main {
  flex-grow: 1;
  display: flex;
  position: relative;

  .navbar {
    position: absolute;
    bottom: 0;
    margin: 0 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ul {
      li {
        a {
          display: flex;
          font-family: $fontMono;
          padding: 0.15rem 0.5rem;

          &:hover {
            color: #000;
            background: $colorSpecial;
          }

          small {
            padding-right: 0.5rem;
          }
        }
      }
    }

    .outer-link {
      display: flex;
      align-items: center;
    }

    .space {
      margin: 0.75rem;
    }
  }

  .about {
    padding: 2rem;
    max-width: 800px;
    margin: auto;
    line-height: 1.6;
    border-radius: 8px;

    h2 {
      font-family: $fontHeader;
      margin-bottom: 1rem;
      text-align: center;
      color: $colorSpecial;
      font-size: 2rem;
    }

    p {
      margin-bottom: 16px;

      h3 {
        font-family: $fontHeader;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }

    .about-text {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      p:last-child {
        grid-column: 1 / -1;
      }
    }

    .about-contact {
      margin: 1rem 0;

      h3 {
        font-family: $fontHeader;
        color: $colorSpecial;
      }
    }

    .about-link {
      background: $colorSpecial;
      padding: 0.25rem;
      width: calc(50% - 1rem);

      a {
        display: flex;
        justify-content: center;
        color: #000;
      }
    }
  }

  .programming {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 3fr;
    align-items: start;

    .project-list {
      @include underline-a;

      h3 {
        margin-left: 5px;
      }

      hr {
        margin: 0.25rem 0;
        width: 220px;
      }

      .top-hr {
        margin-top: 2.5rem;
      }

      li {
        margin: 0.25rem 0;
        font-size: 0.9rem;

        a {
          text-align: left;
        }

        a:hover {
          color: $colorSpecial;
        }
      }
    }

    .project-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      margin-right: 1rem;

      hr {
        border: 1px solid $colorSpecial;
        width: 100%;
        margin: 0.5rem 0;
      }

      .tags {
        display: flex;
        gap: 0.25rem;
        margin: 0.7rem 0;

        .tag {
          font-size: 0.75rem;
          padding: 0.25rem;
          color: $colorSpecial;

          &:hover {
            background: $colorSpecial;
            color: #000;
          }
        }
      }

      .links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
        font-size: 0.75rem;

        .github {
          padding: 0.5rem;
          border: 1px solid #000;

          &:hover {
            border: 1px solid #fff;
          }
        }

        .live-demo {
          border: 1px solid $colorSpecial;
          color: $colorSpecial;
          padding: 0.5rem;
          font-size: 1.1rem;
          display: flex;
          align-items: center;

          svg {
            margin-left: 0.5rem;
          }

          &:hover {
            border: 1px solid $colorSpecial;
            background: $colorSpecial;
            color: #000;
          }
        }

        .github {
          display: flex;
          align-items: center;

          svg {
            margin-left: 0.5rem;
          }
        }
      }

      .images {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
        margin: 1rem;

        .image-wrapper {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }

      .image-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        .enlarged-image {
          max-width: 80%;
          max-height: 80%;
        }

        .close-button {
          position: absolute;
          top: 130px;
          right: 100px;
          padding: 5px 10px;
          font-size: 24px;
          color: white;
          background: none;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}

@import './scss/responsive';
