@mixin underline-a {
  a {
    text-decoration: none;
    text-align: center;
    position: relative;
    display: inline-block;
    color: #fff;
    margin-left: 0.2rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.2rem;
      width: 0;
      height: 0.1rem;
      background-color: $colorSpecial;
      transition: width 0.3s linear;
    }

    &:hover::before {
      width: 100%;
    }

    &:hover::after {
      content: ' <';
      position: absolute;
      top: 0;
      right: -0.5rem;
      width: 0;
      height: 0.1rem;
      background-color: $colorSpecial;
      transition: width 0.3s linear;
    }

    &.active::before {
      width: 100%;
    }
  }
}
