@media (max-width: 650px) {
  .page {
    margin: 0.35rem;
    padding: 0.35rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    height: auto;

    h1 {
      font-size: 2rem;
    }

    a {
      padding: 0;
      width: 100%;
    }

    .header-links {
      .internal-links {
        font-size: 0.45rem;
      }

      .external-links {
        font-size: 0.55rem;

        svg {
          height: 5px;
        }
      }
    }
  }

  .main {
    align-items: flex-start;
    justify-content: space-evenly;

    .navbar {
      display: none;
    }

    .about {
      h2,
      h3 {
        font-size: 0.95rem;
      }

      p {
        font-size: 0.45rem;
      }

      .about-link {
        a {
          font-size: 0.45rem;
        }
      }
    }

    .design {
      font-size: 0.7rem;
    }

    .programming {
      gap: 0.25rem;

      .project-list {
        a,
        p {
          font-size: 0.35rem;
        }

        h2,
        h3 {
          font-size: 0.35rem;
        }

        hr {
          width: auto;
        }

        .top-hr {
          margin-top: 1rem;
        }

        li {
          margin: 0;
          padding: 0;
          line-height: 0.7;
        }
      }

      .project-card {
        width: 250px;
        margin: 0;
        a,
        p {
          font-size: 0.45rem;
        }

        h2,
        h3 {
          font-size: 0.75rem !important;
        }

        hr {
          margin: 0;
        }

        .links {
          margin: 0;
          padding: 0;
          a,
          p {
            font-size: 0.45rem;
          }

          h2,
          h3 {
            font-size: 0.75rem;
          }

          .live-demo {
            padding: 0.25rem;
            font-size: 0.45rem;
            margin: 0;
          }
        }

        .tags {
          width: 200px !important;
          li {
            flex-direction: column;
            padding: 0 !important;
            margin: 0 !important;
            font-size: 0.35rem !important;
          }
        }

        .images {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: 650px) and (max-width: 819px) {
  .main {
    .about {
      h2 {
        line-height: 1.3;
        text-align: left !important;
      }
      .about-text {
        grid-template-columns: 1fr !important;
      }
    }

    .programming {
      gap: 0.5rem;
      grid-template-columns: 1fr 3fr;
      justify-items: center;
      align-items: start;

      .project-list {
        font-size: 0.75rem;
        hr {
          width: auto;
        }

        a {
          text-align: left;
        }

        li {
          margin: 0.25rem 0;
        }
      }

      .project-card {
        font-size: 0.75rem;

        h2 {
          margin-top: 8px;
        }

        .tags {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        }

        .images {
          flex-direction: column;

          .image-wrapper {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-height: 1050px) {
  .main {
    .navbar {
      display: none;
    }
  }
}
